let terra = {
    itemIcon: "./icons/terra.gif",
    itemName: "Terra",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["character"]
}

let leteRiver = {
    itemIcon: "./icons/ultros.gif",
    itemName: "Lete River",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let moblizWor = {
    itemIcon: "./icons/phunbaba.gif",
    itemName: "Phunbaba",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}
let sealedGate = {
    itemIcon: "./icons/maduin.gif",
    itemName: "Sealed Gate",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let whelk = {
    itemIcon: "./icons/whelk.gif",
    itemName: "Whelk",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let zozo = {
    itemIcon: "./icons/ramuh.gif",
    itemName: "Zozo",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}

let strago = {
    itemIcon: "./icons/strago.gif",
    itemName: "Strago",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["character"]
}

let burningHouse = {
    itemIcon: "./icons/flame-eater.gif",
    itemName: "Burning House",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let ebotsRock = {
    itemIcon: "./icons/ebots-rock.gif",
    itemName: "Ebot's Rock",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}
let fanaticsTower = {
    itemIcon: "./icons/fanatics-tower.gif",
    itemName: "Fanatics Tower",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}



let mog = {
    itemIcon: "./icons/mog.gif",
    itemName: "Mog",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["character"]
}

let loneWolf = {
    itemIcon: "./icons/lone-wolf.gif",
    itemName: "Lone Wolf",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}

let relm = {
    itemIcon: "./icons/relm.gif",
    itemName: "Relm",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["character"]
}

let esperMountain = {
    itemIcon: "./icons/goddess-statue.gif",
    itemName: "Esper Mountain",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let owzersMansion = {
    itemIcon: "./icons/owzer.gif",
    itemName: "Owzer's Mansion",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}


let gogo = {
    itemIcon: "./icons/gogo.gif",
    itemName: "GoGo",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["character"]
}

let zoneEater = {

    itemIcon: "./icons/zone-eater.gif",
    itemName: "Zone Eater",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}

let setzer = {
    itemIcon: "./icons/setzer.gif",
    itemName: "Setzer",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["character"]
}

let darylsTomb = {
    itemIcon: "./icons/darillstomb.gif",
    itemName: "Darill's Tomb",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}
let kohlingen = {
    itemIcon: "./icons/interceptor.gif",
    itemName: "Kohlingen",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["both"]
}

let edgar = {
    itemIcon: "./icons/edgar.gif",
    itemName: "Edgar",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["character"]
}

let ancientCastle = {
    itemIcon: "./icons/odin.gif",
    itemName: "Ancient Castle",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}
let figaroCastleWob = {
    itemIcon: "./icons/doma-castle-wob.png",
    itemName: "Figaro Castle WoB",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["both"]
}
let figaroCastleWor = {
    itemIcon: "./icons/tentacle.gif",
    itemName: "Figaro Castle WoR",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}

let gau = {
    itemIcon: "./icons/gau.gif",
    itemName: "Gau",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["character"]
}

let serpentTrench = {
    itemIcon: "./icons/serpent-trench.gif",
    itemName: "Serpent Trench",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let veldt = {
    itemIcon: "./icons/gau-veldt.gif",
    itemName: "Gau on Veldt",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["both"]
}

let cyan = {
    itemIcon: "./icons/cyan.gif",
    itemName: "Cyan",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["character"]
}


let domaWob = {
    itemIcon: "./icons/general.gif",
    itemName: "Doma WoB",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let domaWor = {
    itemIcon: "./icons/wrexsoul-npc.gif",
    itemName: "Doma WoR",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]

}
let mtZozo = {
    itemIcon: "./icons/zozo-cave.gif",
    itemName: "Mt. Zozo",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}



let locke = {
    itemIcon: "./icons/locke.gif",
    itemName: "Locke",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["character"]
}

let narsheWeaponShop = {
    itemIcon: "./icons/ragnarok.gif",
    itemName: "Narshe Weapon Shop WoR",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}
let phoenixCave = {
    itemIcon: "./icons/phoenix.gif",
    itemName: "Phoenix Cave",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}
let southFigaroCave = {
    itemIcon: "./icons/tunnel-armor.gif",
    itemName: "Tunnel Armor",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}

let celes = {
    itemIcon: "./icons/celes.gif",
    itemName: "Celes",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["character"]
}

let magitekIfrit = {
    itemIcon: "./icons/ifrit.gif",
    itemName: "Ifrit",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let magitek24 = {
    itemIcon: "./icons/no-24.gif",
    itemName: "No. 24",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let magitekCrane = {
    itemIcon: "./icons/crane.gif",
    itemName: "Crane",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let operaHouse = {
    itemIcon: "./icons/opera-house-weight.gif",
    itemName: "Opera House",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let southFigaro = {
    itemIcon: "./icons/celes-chained.gif",
    itemName: "South Figaro",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["both"]
}

let shadow = {
    itemIcon: "./icons/shadow.gif",
    itemName: "Shadow",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["character"]

}

let fcAirBase = {
    itemIcon: "./icons/fc-airbase.gif",
    itemName: "Floating Continent - Air Base",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}

let fcAtma = {
    itemIcon: "./icons/atma.gif",
    itemName: "Floating Continent Atma",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let fcWait = {
    itemIcon: "./icons/shadow.gif",
    itemName: "Wait for Shadow!",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let gausFather = {
    itemIcon: "./icons/gaus-dad.gif",
    itemName: "Gaus Father",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let veldtCaveWor = {
    itemIcon: "./icons/srbehemoth.gif",
    itemName: "Veldt WoR",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}

let sabin = {
    itemIcon: "./icons/sabin.gif",
    itemName: "Sabin",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["character"]
}

let barenFalls = {
    itemIcon: "./icons/baren-falls.gif",
    itemName: "Baren Falls",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let collapsingHouse = {
    itemIcon: "./icons/burningHouse.gif",
    itemName: "Collapsing House",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}
let imperialCamp = {
    itemIcon: "./icons/imperial-camp.gif",
    itemName: "Imperial Camp",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let mtKolts = {
    itemIcon: "./icons/vargas.gif",
    itemName: "Vargas",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let phantomTrain = {
    itemIcon: "./icons/ghost-train.gif",
    itemName: "Ghost Train",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}

let umaro = {
    itemIcon: "./icons/umaro.gif",
    itemName: "Umaro",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["character"]
}

let umaroBoss = {
    itemIcon: "./icons/umaro-boss.gif",
    itemName: "Umaro (boss)",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}

let open = {
    itemIcon: "./icons/cid.gif",
    itemName: "Free",
    itemClickStates: ["collected"],
    itemAvailability: ["always"]
}
let tzen = {
    itemIcon: "./icons/tzen-woods.gif",
    itemName: "Woods Dealer",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["both"]
}
let tritoch = {
    itemIcon: "./icons/tritoch.gif",
    itemName: "Tritoch",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}
let kefkaAtNarshe = {
    itemIcon: "./icons/kefka-narshe.gif",
    itemName: "Kefka At Narshe",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wob"]
}
let doomGaze = {
    itemIcon: "./icons/doomgaze.gif",
    itemName: "DoomGaze",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}
let auctionHouseOne = {
    itemIcon: "./icons/auction-house.gif",
    itemName: "Auction House 1",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["both"]
}
let auctionHouseTwo = {
    itemIcon: "./icons/auction-house.gif",
    itemName: "Auction House 2",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["both"]
}

let dragonsGroup = {
    itemIcon: "./icons/dragon.gif",
    itemName: "Dragons",
    itemClickStates: ["collected"],
    itemAvailability: ["always"]
}

let blueDragon = {
    itemIcon: "./icons/blue-dragon.gif",
    itemName: "Blue Dragon",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}

let dirtDragon = {
    itemIcon: "./icons/dirt-dragon.gif",
    itemName: "Dirt Dragon",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}

let goldDragon = {
    itemIcon: "./icons/gold-dragon.gif",
    itemName: "Gold Dragon",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}

let iceDragon = {
    itemIcon: "./icons/ice-dragon.gif",
    itemName: "Ice Dragon",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}

let redDragon = {
    itemIcon: "./icons/red-dragon.gif",
    itemName: "Red Dragon",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}

let skullDragon = {
    itemIcon: "./icons/skull-dragon.gif",
    itemName: "Skull Dragon",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}

let stormDragon = {
    itemIcon: "./icons/storm-dragon.gif",
    itemName: "Storm Dragon",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}

let whiteDragon = {
    itemIcon: "./icons/white-dragon.gif",
    itemName: "White Dragon",
    itemClickStates: ["uncollected", "collected"],
    itemAvailability: ["wor"]
}

let charge = {
    itemIcon:["./icons/charge.png","./icons/charge-lvl1.png","./icons/charge-lvl2.png","./icons/charge-lvl3.png","./icons/charge-lvl4.png"],
    itemName:"charge", 
    itemClickStates: ["uncollected","collected","collected","collected","collected"], 
    itemAvailability: ["always"]}
let ice = {itemIcon:"./icons/ice.png", itemName:"ice", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}
let wave = {itemIcon:"./icons/wave.png", itemName:"wave", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}
let spazer = {itemIcon:"./icons/spazer.png", itemName:"spazer", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}
let plasma = {itemIcon:"./icons/plasma.png", itemName:"plasma", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}

let morph = {itemIcon:"./icons/morph.png", itemName:"morph", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}
let varia = {itemIcon:["./icons/varia.png","./icons/heatshield.png","./icons/varia.png"], itemName:"varia", itemClickStates: ["uncollected", "collected", "collected"], itemAvailability: ["always"]}
let springball = {itemIcon:"./icons/springball.png", itemName:"springball", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}
let hijump = {itemIcon:"./icons/hijump.png", itemName:"hijump", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}
let space = {itemIcon:["./icons/space.png","./icons/doublejump.png","./icons/space.png"], itemName:"space", itemClickStates: ["uncollected", "collected","collected"], itemAvailability: ["always"]}

let bombs = {itemIcon:"./icons/bomb.png", itemName:"bombs", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}
let gravity = {itemIcon:["./icons/gravity.png","./icons/pressurevalve.png","./icons/gravity.png"], itemName:"gravity", itemClickStates: ["uncollected", "collected", "collected"], itemAvailability: ["always"]}    
let ridley = {itemIcon:"./icons/ridley.png", itemName:"ridley", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}
let speed = {itemIcon:"./icons/speed.png", itemName:"speed", itemClickStates: ["uncollected","collected"], itemAvailability: ["always"]}
let screw = {itemIcon:"./icons/screw.png", itemName:"screw", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}

let bigSpacer = {itemIcon:"./icons/spacer.png", itemName:"spacer", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}
let spacer = {itemIcon:"./icons/spacer.png", itemName:"spacer", itemClickStates:["collected key"], itemAvailability:["always"]}
let grappling = {itemIcon:"./icons/grappling.png", itemName:"grappling", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}
let kraid = {itemIcon:"./icons/kraid.png", itemName:"kraid", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}
let phantoon = {itemIcon:"./icons/phantoon.png", itemName:"phantoon", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}
let draygon = {itemIcon:"./icons/draygon.png", itemName:"draygon", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}
let xray = {itemIcon:"./icons/xray.png", itemName:"xray", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}

let missile = {itemIcon:"./icons/missile.png", itemName:"missile1", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}
let superMissile = {itemIcon:"./icons/super.png", itemName:"super1", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}
let pbomb = {itemIcon:"./icons/pbomb.png", itemName:"pbomb", itemClickStates: ["uncollected", "collected"], itemAvailability: ["always"]}

let crLabel = {itemIcon:"./icons/cr.png", itemName:"crLabel", itemClickStates: ["collected key"], itemAvailability: ["always"]}
let crateriaKey1 = {itemIcon:"./icons/key1.png", itemName:"crateriaKey1", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}
let crateriaKey2 = {itemIcon:"./icons/key2.png", itemName:"crateriaKey2", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}
let crateriaKeyBoss = {itemIcon:"./icons/keyBoss.png", itemName:"crateriaKeyBoss", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}

let brLabel = {itemIcon:"./icons/br.png", itemName:"brLabel", itemClickStates: ["collected key"], itemAvailability: ["always"]}
let brinstarKey1 = {itemIcon:"./icons/key1.png", itemName:"brinstarKey1", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}
let brinstarKey2 = {itemIcon:"./icons/key2.png", itemName:"brinstarKey2", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}
let brinstarKeyBoss = {itemIcon:"./icons/keyBoss.png", itemName:"brinstarKeyBoss", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}

let unLabel = {itemIcon:"./icons/un.png", itemName:"unLabel", itemClickStates: ["collected key"], itemAvailability: ["always"]}
let upperNorfairKey1 = {itemIcon:"./icons/key1.png", itemName:"upperNorfairKey1", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}
let upperNorfairKey2 = {itemIcon:"./icons/key2.png", itemName:"upperNorfairKey2", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}
let upperNorfairKeyBoss = {itemIcon:"./icons/keyBoss.png", itemName:"upperNorfairKeyBoss", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}

let maLabel = {itemIcon:"./icons/ma.png", itemName:"maLabel", itemClickStates: ["collected key"], itemAvailability: ["always"]}
let maridiaKey1 = {itemIcon:"./icons/key1.png", itemName:"maridiaKey1", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}
let maridiaKey2 = {itemIcon:"./icons/key2.png", itemName:"maridiaKey2", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}
let maridiaKeyBoss = {itemIcon:"./icons/keyBoss.png", itemName:"maridiaKeyBoss", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}

let wsLabel = {itemIcon:"./icons/ws.png", itemName:"wsLabel", itemClickStates: ["collected key"], itemAvailability: ["always"]}
let wreckedShipKey1 = {itemIcon:"./icons/key1.png", itemName:"wreckedShipKey1", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}
let wreckedShipKeyBoss = {itemIcon:"./icons/keyBoss.png", itemName:"wreckedShipKeyBoss", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}

let lnLabel = {itemIcon:"./icons/ln.png", itemName:"lnLabel", itemClickStates: ["collected key"], itemAvailability: ["always"]}
let lowerNorfairKey1 = {itemIcon:"./icons/key1.png", itemName:"lowerNorfairKey1", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}
let lowerNorfairKeyBoss = {itemIcon:"./icons/keyBoss.png", itemName:"lowerNorfairKeyBoss", itemClickStates: ["uncollected key", "collected key"], itemAvailability: ["always"]}

let trackerKeySanityJson = [
    {
        groupIcon: morph,
        groupedItems: [
            
            crLabel, crateriaKey1, crateriaKey2, crateriaKeyBoss,
            morph,               
            hijump,
            charge,
                    
            brLabel, brinstarKey1, brinstarKey2, brinstarKeyBoss,             
            unLabel, upperNorfairKey1, upperNorfairKey2, upperNorfairKeyBoss, 
            
            
            bombs,            
            speed,  
            ice,     
            maLabel, maridiaKey1, maridiaKey2, maridiaKeyBoss, 

            
            wsLabel, wreckedShipKey1, spacer, wreckedShipKeyBoss, 
            varia,        
            space,
            wave,    
            lnLabel, lowerNorfairKey1, spacer, lowerNorfairKeyBoss,
            
            spacer, spacer, 
            ridley,   
            gravity,  
            springball,
            spazer,
            spacer, spacer,          
            kraid,
            
            phantoon,
            draygon,
            screw,
            plasma,

            
    ]
    }]

let trackerJson = [
    {
        groupIcon: morph,
        groupedItems: [
            
            charge,  
            ice,
            wave,
            spazer,  
            plasma,  

            morph,
            varia,  
            springball,            
            hijump,       
            space,        

            bombs,   
            gravity,   
            ridley,            
            speed,  
            screw,

            grappling,    
            kraid,
            phantoon,
            draygon,  
            xray,              
    ]
    }]
    
//     ,{
//         groupIcon: crLabel,
//         groupedItems: [
//             spacer,  crLabel, crateriaKey1, crateriaKey2, crateriaKeyBoss, 
//             spacer,  brLabel, brinstarKey1, brinstarKey2, brinstarKeyBoss, 
//             spacer,  unLabel, upperNorfairKey1, upperNorfairKey2, upperNorfairKeyBoss, 
//             spacer,  maLabel, maridiaKey1, maridiaKey2, maridiaKeyBoss, 
//             spacer,  wsLabel, wreckedShipKey1, spacer, wreckedShipKeyBoss, 
//             spacer,  lnLabel, lowerNorfairKey1, spacer, lowerNorfairKeyBoss]
//     }



export default trackerJson